import { withStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'

export const AllowSwitch = withStyles({
  switchBase: {
    color: '#fff',
    '&$checked': {
      color: '#fff'
    },

    '&$checked + $track': {
      backgroundColor: '#27a448',
      opacity: 1,
      height: 16
    }
  },
  checked: {},
  track: {
    height: 16
  }
})(Switch)
