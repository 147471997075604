/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
import React from 'react'
import PropTypes from 'prop-types'
import RuleEditorStyled from './styledComponent'
import Dropdown from '../Dropdown/Loadable'
import Button from '../Button/Loadable'
import _ from 'lodash'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import DivideLine from '../DivideLine/Loadable'
import SliderComponent from '../SliderComponent/Loadable'
import CheckboxComponent from '../CheckboxComponent/Loadable'
import ColorRangePicker from '../ColorRangePicker/Loadable'
import MESSAGE from './message'
import { LIST_ZONE_COLORS, PERSON_TARGET, COLOR_VIOLATION_EVENT, UNAUTHORIZED_EVENT, TRUCK_TARGET, NEW_ZONE_ID } from '../../utils'
import { AllowSwitch } from './customComponent'
import colors from '../../colors'

import InfoIcon from '../../asset/images/info.svg'

const truckCondition = [
  {
    class_type: TRUCK_TARGET,
    class_id: 2,
    class_confidence: 0.6
  }
]

class RuleEditor extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      targetConfidence: 0.6,
      listTargetVehicles: [],
      listConditions: truckCondition,
      targetColorCheckAll: false,
      activeGridColor: props.activeZone.grid_color || LIST_ZONE_COLORS[0],
      activeAction: this.findActiveActions(props),
      activeTarget: { text: `-- ${this.getMessage('select_target_dropdown')} --`, value: '' },
      isAddColorRangeButtonHide: false,
      conditionObject: truckCondition[0],
      showColorTooltip: false
    }
  }

  componentDidMount() {
    const { activeZone } = this.props
    if (activeZone) {
      this.changeSomeStateForEditor(activeZone)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeZone } = this.props
    if (prevProps.activeZone !== activeZone) {
      this.changeSomeStateForEditor(activeZone, prevProps)
      this.revertStateAddColorRangeButton()
    }
    document.addEventListener('mousedown', (e) => this.handleClickOutside(e))
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', (e) => this.handleClickOutside(e))
  }

  handleClickOutside(e) {
    if (this.colorRangeTooltipRef && !this.colorRangeTooltipRef.contains(e.target)) {
      this.closeColorRangeTooltip()
    }
  }

  revertStateAddColorRangeButton = () => {
    this.setState({
      isAddColorRangeButtonHide: false
    })
  }

  findActiveTarget(rule) {
    return this.props.targetOptions.find((target) => target.value === rule.class_type)
  }

  changeSomeStateForEditor(activeZone) {
    const rule = activeZone.features[0]
    const { conditions } = activeZone
    const isConditionEmpty = _.isEmpty(conditions)
    if (typeof rule !== 'undefined') {
      if (rule.class_type === '' || rule.class_type === null) {
        this.setState({
          activeTarget: { text: `-- ${this.getMessage('select_target_dropdown')} --`, value: '' },
          targetConfidence: 0.6
        })
      } else {
        this.setState({
          targetConfidence: rule.class_confidence,
          activeAction: this.props.violationOptions.find((violation) => violation.value === activeZone.violation_name),
          activeTarget: this.findActiveTarget(rule) || { text: `-- ${this.getMessage('select_target_dropdown')} --`, value: '' }
        })
      }
    }
    if (conditions !== null && !isConditionEmpty) {
      this.setState({
        conditionObject: conditions.any_features_present[0]
      })
    }
  }

  findActiveActions(props) {
    const action = props.violationOptions.find((violation) => violation.value === props.activeZone.violation_name)
    if (action) {
      return action
    } else {
      return { text: this.getContent('select_event_dropdown'), value: '' }
    }
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.language]
    return msg[property]
  }

  async handleActionChange(option) {
    this.setState({
      activeAction: option
    })
    if (option.value === COLOR_VIOLATION_EVENT) {
      const personTarget = this.props.targetOptions.find((target) => target.value === PERSON_TARGET)
      await this.handleTargetChange(personTarget)
    }
    this.props.handleViolationChange(option.value, option.violation_id)
  }

  handleAllowChange(event) {
    this.props.handleToggleAllow(event.target.checked)
  }

  renderActionContent() {
    const selectedValue = this.props.violationOptions.find((violation) => violation.value === this.props.activeZone.violation_name)
    const initialSelectedValue = selectedValue || { text: `-- ${this.getMessage('select_event_dropdown')} --`, value: '' }

    return (
      <div className="rule-row-container">
        <Dropdown
          options={this.props.violationOptions}
          label={this.getMessage('event_label')}
          onChange={(option) => this.handleActionChange(option)}
          value={initialSelectedValue}
          labelPosition="left"
          buttonColor={colors.jadeGreen}
          language={this.props.language}
          disabled={this.props.shouldDisableEditor}
        />
        {this.renderAllowSwitch(initialSelectedValue)}
      </div>
    )
  }

  renderAllowSwitch(actionValue) {
    let output = null
    if (actionValue.value !== '' && actionValue.value !== UNAUTHORIZED_EVENT) {
      output = (
        <div className="switch-wrapper">
          <div className="switch-label text-style">{this.getMessage('allow_label')}</div>
          <FormControlLabel
            control={
              <AllowSwitch
                checked={this.props.activeZone?.allowed}
                onChange={(e) => this.handleAllowChange(e)}
                disabled={this.props.shouldDisableEditor}
              />
            }
            label=""
          />
        </div>
      )
    }
    return output
  }

  formatTargetOptions = () => {
    const { targetOptions } = this.props
    let tempOptions = [...targetOptions]
    if (this.state.activeAction.value === COLOR_VIOLATION_EVENT) {
      tempOptions = [...targetOptions].filter((target) => target.value === PERSON_TARGET)
    }
    return tempOptions
  }

  renderTargetContent() {
    const tempOptions = this.formatTargetOptions()
    return (
      <div className="rule-row-container">
        <Dropdown
          options={tempOptions}
          label={this.getMessage('target_label')}
          onChange={(target) => this.handleTargetChange(target)}
          value={this.state.activeTarget}
          labelPosition="left"
          buttonColor={colors.jadeGreen}
          language={this.props.language}
          disabled={this.props.shouldDisableEditor}
        />
        {this.renderClassConfidenceSlider(this.state.activeTarget)}
        {this.renderObjectMinHeightSlider()}
        {this.renderSubTargetContent(this.state.activeTarget)}
      </div>
    )
  }

  async handleTargetChange(option) {
    await this.setState({
      activeTarget: option
    })
    let tempFeatures = []
    tempFeatures.push({
      class_confidence: Number(this.state.targetConfidence.toFixed(1)),
      class_type: option.value,
      color_confidence: this.props.activeZone.features[0].color_confidence,
      color_type: this.props.activeZone.features[0].color_type
    })
    this.props.handleEachColorTargetChange(tempFeatures)
  }

  handleTargetConfChange(e, value) {
    this.setState({
      targetConfidence: value
    })
  }

  handleTargetConfChangeEnd(e, value) {
    this.props.handleTargetConfidenceChange(e, Number(value.toFixed(1)))
  }

  handleObjectMinimumheightChange(value, classPropertyName) {
    const { object_size } = this.props.activeZone
    const tempObjectMinHeight = [...object_size].map((eachObj) => {
      if (eachObj.class_type === classPropertyName) {
        return Object.assign({}, eachObj, { min_value: value })
      }
      return eachObj
    })
    this.props.handleEachTargetMinimumHeightChange(tempObjectMinHeight)
  }

  renderObjectMinHeightSlider() {
    let output = null
    const activeTargetClass = this.props.activeZone.object_size.find((obj) => obj.class_type === this.state.activeTarget.value)
    if (this.state.activeTarget.value !== '' && typeof activeTargetClass !== 'undefined') {
      output = (
        <div className="slider-wrapper">
          <SliderComponent
            label={this.getMessage('min_height_label')}
            value={activeTargetClass.min_value}
            min={0}
            max={this.props.cameraResolutionHeight}
            step={this.props.cameraResolutionHeight / 100}
            valueLabelDisplay={'off'}
            onChange={(e, value) => this.handleObjectMinimumheightChange(value, activeTargetClass.class_type)}
            onChangeCommitted={(e, value) => this.handleObjectMinimumheightChange(value, activeTargetClass.class_type)}
            leftFooterLabel={this.getMessage('slider_short_label')}
            rightFooterLabel={this.getMessage('slider_tall_label')}
            disabled={this.props.shouldDisableEditor}
            valueType={'percent'}
            tooltipInfo={this.getMessage('min_height_tooltip')}
          />
        </div>
      )
    }
    return output
  }

  renderClassConfidenceSlider(targetValue) {
    let output = null
    if (targetValue.value !== '') {
      output = (
        <div className="slider-wrapper">
          <SliderComponent
            label={this.getMessage('sensitivity_label')}
            value={this.state.targetConfidence}
            min={0}
            max={1}
            step={0.1}
            valueLabelDisplay={'off'}
            onChange={(e, value) => this.handleTargetConfChange(e, value)}
            onChangeCommitted={(e, value) => this.handleTargetConfChangeEnd(e, value)}
            leftFooterLabel={this.getMessage('slider_low_label')}
            rightFooterLabel={this.getMessage('slider_high_label')}
            disabled={this.props.shouldDisableEditor}
            valueType={'number'}
            tooltipInfo={this.getMessage('detection_confidence_tooltip')}
          />
        </div>
      )
    }
    return output
  }

  renderColorRangeLabel() {
    return <div className="color-range-label">{this.getMessage('color_range_title')}</div>
  }

  renderColorRangeHintText = () => {
    return <div className="color-range-hint">{this.getMessage('color_range_hint_title')}</div>
  }

  toggleColorRangeTooltip = () => {
    this.setState({
      showColorTooltip: !this.state.showColorTooltip
    })
  }

  closeColorRangeTooltip = () => {
    this.setState({
      showColorTooltip: false
    })
  }

  renderInfoIcon() {
    return (
      <div className="icon-wrapper" onClick={this.toggleColorRangeTooltip} ref={(ref) => (this.colorRangeTooltipRef = ref)}>
        <img src={InfoIcon} alt="" />
      </div>
    )
  }

  renderTooltipInfo() {
    let output = null
    if (this.state.showColorTooltip) {
      output = (
        <div className="color-range-tooltip">
          <p>{this.getMessage('color_range_tooltip_1')}</p>
          <div>
            <span>{this.getMessage('color_range_tooltip_orange')}</span>
            <span>{this.getMessage('color_range_tooltip_yellow')}</span>
            <span>{this.getMessage('color_range_tooltip_green')}</span>
            <span>{this.getMessage('color_range_tooltip_blue')}</span>
            <span>{this.getMessage('color_range_tooltip_purple')}</span>
            <span>{this.getMessage('color_range_tooltip_red')}</span>
          </div>
        </div>
      )
    }
    return output
  }

  addNewColorRange = () => {
    const willBeNewColor = { min_hue: 0, max_hue: 1, range_name: '', name: '', cid: Date.now(), create: true }
    const tempColorRange = [...this.props.activeZone.color_range, willBeNewColor]
    this.props.handleColorRangeListChange(tempColorRange)
  }

  isAllRange = (leftHue, rightHue) => {
    return (
      (leftHue === 0 && rightHue === 359) ||
      (leftHue === 0 && rightHue === 360) ||
      (leftHue === 359 && rightHue === 0) ||
      (leftHue === 360 && rightHue === 0)
    )
  }

  shouldDisabledAddColorRangeBtn = () => {
    const { color_range } = this.props.activeZone
    const colorIndex = color_range.find((eachColor) => this.isAllRange(eachColor.min_hue, eachColor.max_hue))
    if (color_range.length === 1) {
      const { min_hue, max_hue } = color_range[0]
      return this.isAllRange(min_hue, max_hue)
    } else if (color_range.length > 1 && color_range.length < 6) {
      return colorIndex !== undefined
    } else {
      return color_range.length === 6
    }
  }

  shouldHideAddColorRangeButton = (isShow) => {
    this.setState({
      isAddColorRangeButtonHide: isShow
    })
  }

  onColorRangeEdit = (color) => {
    const { color_range } = this.props.activeZone
    let tempColorRangeList = []
    tempColorRangeList = [...color_range].reduce((listNewColor, eachColor) => {
      if (eachColor.cid === color.cid) {
        listNewColor.push(color)
      } else {
        listNewColor.push(eachColor)
      }
      return listNewColor
    }, [])
    this.props.handleColorRangeListChange(tempColorRangeList)
  }

  onColorRangeDelete = (cid, range_name) => {
    this.props.deleteEachColorRange(cid, range_name)
  }

  checkWhetherColorNameDuplicate = (name, cid) => {
    const { color_range } = this.props.activeZone
    const color = color_range.find((eachColor) => eachColor.range_name === name && eachColor.cid !== cid)
    return typeof color !== 'undefined'
  }

  revertBackColorRange = (cid) => {
    this.props.revertBackColorRange(cid)
  }

  renderAllColorRanges() {
    const { activeZone, listColorRanges } = this.props
    return activeZone.color_range.map((color) => {
      return (
        <ColorRangePicker
          key={color.range_name}
          colorRangeList={listColorRanges}
          activeColor={color}
          onColorRangeEdit={this.onColorRangeEdit}
          onColorRangeDelete={this.onColorRangeDelete}
          shouldHideAddColorRangeButton={this.shouldHideAddColorRangeButton}
          setChangeSetting={this.props.setChangeSetting}
          checkWhetherColorNameDuplicate={this.checkWhetherColorNameDuplicate}
          revertBackColorRange={this.revertBackColorRange}
          savedColorRangeList={activeZone.color_range}
          language={this.props.language}
        />
      )
    })
  }

  renderAddColorRangeButton() {
    let output = (
      <Button
        id="add-color-range-btn"
        invert
        onClick={this.addNewColorRange}
        text={this.getMessage('add_range_button')}
        disabled={this.shouldDisabledAddColorRangeBtn()}
      />
    )
    if (this.state.isAddColorRangeButtonHide) {
      output = null
    }

    return output
  }

  renderColorRangeList() {
    return (
      <div className="color-range-list-wrapper">
        <DivideLine className="sub-rule-line" />
        <div className="color-range-list-label">
          {this.renderColorRangeLabel()}
          {this.renderColorRangeHintText()}
          {this.renderInfoIcon()}
          {this.renderTooltipInfo()}
        </div>
        <div className="list-card-wrapper">{this.renderAllColorRanges()}</div>
        {this.renderAddColorRangeButton()}
      </div>
    )
  }

  renderSubTargetContent(targetValue) {
    let output = null
    const isSelectedTarget = targetValue.value !== ''
    const notVehicleTarget = targetValue.value === PERSON_TARGET
    const notUnauthorizedAction = this.state.activeAction.value !== UNAUTHORIZED_EVENT
    if (isSelectedTarget && notVehicleTarget && notUnauthorizedAction) {
      output = this.renderColorRangeList()
    }
    return output
  }

  async handleAlertConditionConfidenceChange(e, value) {
    const { conditions } = this.props.activeZone
    await this.setState({
      conditionObject: { ...this.state.conditionObject, class_confidence: value }
    })
    this.props.handleConditionChange({ value: TRUCK_TARGET, checked: conditions !== null }, this.state.conditionObject)
  }

  renderAlertConditionConfidenceSlider() {
    let output = null
    const { conditions } = this.props.activeZone
    const isConditionEmpty = _.isEmpty(conditions)
    if (conditions !== null && !isConditionEmpty) {
      const condition = conditions.any_features_present[0]
      output = (
        <SliderComponent
          label={this.getMessage('sensitivity_label')}
          value={condition.class_confidence}
          min={0}
          max={1}
          step={0.1}
          valueLabelDisplay="off"
          onChange={(e, value) => this.handleAlertConditionConfidenceChange(e, value)}
          onChangeCommitted={(e, value) => this.handleAlertConditionConfidenceChange(e, value)}
          leftFooterLabel={this.getMessage('slider_low_label')}
          rightFooterLabel={this.getMessage('slider_high_label')}
          disabled={this.props.shouldDisableEditor}
          valueType={'number'}
          tooltipInfo={this.getMessage('detection_confidence_tooltip')}
        />
      )
    }
    return output
  }

  renderAlertConditionMinHeightSlider() {
    let output = null
    const { conditions } = this.props.activeZone
    const isConditionEmpty = _.isEmpty(conditions)
    if (conditions !== null && !isConditionEmpty) {
      const conditionObject = conditions?.any_features_present[0]
      const classObjectSize = this.props.activeZone.object_size.find((eachClass) => eachClass.class_type === conditionObject.class_type)
      output = (
        <SliderComponent
          label={this.getMessage('min_size_label')}
          value={classObjectSize.min_value}
          min={0}
          max={this.props.cameraResolutionHeight}
          step={this.props.cameraResolutionHeight / 100}
          valueLabelDisplay="off"
          onChange={(e, value) => this.handleObjectMinimumheightChange(value, classObjectSize.class_type)}
          onChangeCommitted={(e, value) => this.handleObjectMinimumheightChange(value, classObjectSize.class_type)}
          leftFooterLabel={this.getMessage('slider_small_label')}
          rightFooterLabel={this.getMessage('slider_big_label')}
          disabled={this.props.shouldDisableEditor}
          valueType={'percent'}
          tooltipInfo={this.getMessage('min_size_tooltip')}
        />
      )
    }
    return output
  }

  renderConditionLabel() {
    return (
      <div className="condition-label-wrapper flex flex-column">
        <span className="label">{this.getMessage('condition_label')}</span>
        <span className="sub-label">{this.getMessage('optional_label')}</span>
      </div>
    )
  }

  handleChangeCondition = (checkboxData) => {
    this.props.handleConditionChange(checkboxData, this.state.conditionObject)
  }

  renderConditionContent() {
    let output = null
    const { features, conditions } = this.props.activeZone
    const isConditionEmpty = _.isEmpty(conditions)
    if (features.length > 0 && features[0].class_type === PERSON_TARGET) {
      output = (
        <>
          <div className="condition-row-container">
            {this.renderConditionLabel()}
            <div className="checkbox-wrapper">
              <CheckboxComponent
                checked={conditions !== null && !isConditionEmpty}
                onChange={this.handleChangeCondition}
                label={this.getMessage('truck_condition')}
                value={TRUCK_TARGET}
                disabled={this.props.shouldDisableEditor}
              />
            </div>
          </div>
          <div className="condition-slider-wrapper flex flex-column">
            {conditions !== null && !isConditionEmpty && <div className="truck-label">{this.getMessage('truck')}</div>}
            {this.renderAlertConditionConfidenceSlider()}
            {this.renderAlertConditionMinHeightSlider()}
          </div>

          <DivideLine className="rule-line" />
        </>
      )
    }

    return output
  }

  onGridColorChange(color) {
    this.setState(
      {
        activeGridColor: `0x${color.slice(1)}`
      },
      () => this.props.handleGridColorChange(this.state.activeGridColor)
    )
  }

  onApplyRule() {
    if (this.props.isAddNewZone) {
      this.props.handleOnAddZone()
    } else {
      this.props.onUpdateZone()
    }
  }

  renderGridColorSelect(listColor) {
    return listColor.map((color) => {
      const eachColor = color.slice(1)
      const activeZoneColor = this.props.activeZone.grid_color ? this.props.activeZone.grid_color.slice(2) : LIST_ZONE_COLORS[0].slice(2)
      const activeClassName = eachColor === activeZoneColor ? ' active' : ''
      return (
        <div className={'color-selector-wrapper' + activeClassName} key={color}>
          <div className="color-dot" style={{ backgroundColor: color, border: `2px solid ${color}` }} onClick={() => this.onGridColorChange(color)}>
            &nbsp;
          </div>
        </div>
      )
    })
  }

  renderGridColorContent() {
    const listGridColor = LIST_ZONE_COLORS.map((color) => {
      return `#${color.slice(2)}`
    })
    return (
      <div className="grid-color-wrapper">
        <div className="grid-label text-style">{this.getMessage('grid_color_label')}</div>
        <div className="color-select">{this.renderGridColorSelect(listGridColor)}</div>
      </div>
    )
  }

  shouldDisabledApplyBtn() {
    if (this.props.activeZone.zone_id === NEW_ZONE_ID) {
      return (
        !this.props.isAddNewZone || !this.props.validZone || this.props.invalidPolygon || this.props.isDrawing || this.state.isAddColorRangeButtonHide
      )
    } else {
      return (
        !this.props.isChangeSetting ||
        !this.props.validZone ||
        this.props.invalidPolygon ||
        this.props.isDrawing ||
        this.state.isAddColorRangeButtonHide
      )
    }
  }

  renderApplyRuleButton() {
    const btnText = this.props.isRecalculatingZone ? this.getMessage('processing_btn') : this.getMessage('apply_btn')
    return (
      <div className="apply-btn-wrapper">
        <Button
          id={'apply-btn'}
          onClick={() => this.onApplyRule()}
          text={btnText}
          disabled={this.shouldDisabledApplyBtn()}
          loading={this.props.isRecalculatingZone}
        />
      </div>
    )
  }

  getContent() {
    return (
      <div className="rule-editor-wrapper">
        {this.renderActionContent()}
        <DivideLine className="rule-line" />
        {this.renderTargetContent()}
        <DivideLine className="rule-line" />
        {this.renderConditionContent()}
        {this.renderGridColorContent()}
        {this.renderApplyRuleButton()}
      </div>
    )
  }

  render() {
    return <RuleEditorStyled>{this.getContent()}</RuleEditorStyled>
  }
}

RuleEditor.defaultProps = {
  language: 'EN'
}

RuleEditor.propTypes = {
  activeZone: PropTypes.shape({
    allowed: PropTypes.bool,
    camera_id: PropTypes.number,
    grid_color: PropTypes.string,
    features: PropTypes.arrayOf(
      PropTypes.shape({
        class_confidence: PropTypes.number,
        class_type: PropTypes.string,
        color_confidence: PropTypes.number,
        color_type: PropTypes.string
      })
    ),
    points: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
    violation_id: PropTypes.number,
    violation_name: PropTypes.string,
    zone_id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    object_size: PropTypes.array
  }).isRequired,
  listViolations: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      violation_id: PropTypes.number
    })
  ).isRequired,
  listColorRanges: PropTypes.arrayOf(
    PropTypes.shape({
      min_hue: PropTypes.number,
      max_hue: PropTypes.number,
      name: PropTypes.string,
      range_name: PropTypes.string
    })
  ),
  violationOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
      violation_id: PropTypes.number
    })
  ).isRequired,
  targetOptions: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string,
      class_id: PropTypes.number
    })
  ).isRequired,
  onUpdateZone: PropTypes.func.isRequired,
  handleOnAddZone: PropTypes.func.isRequired,
  handleViolationChange: PropTypes.func.isRequired,
  handleToggleAllow: PropTypes.func.isRequired,
  handleTargetConfidenceChange: PropTypes.func.isRequired,
  handleEachColorTargetChange: PropTypes.func.isRequired,
  handleColorRangeListChange: PropTypes.func.isRequired,
  deleteEachColorRange: PropTypes.func.isRequired,
  handleEachTargetMinimumHeightChange: PropTypes.func,
  handleConditionChange: PropTypes.func.isRequired,
  revertBackColorRange: PropTypes.func.isRequired,
  isChangeSetting: PropTypes.bool.isRequired,
  invalidPolygon: PropTypes.bool.isRequired,
  isAddNewZone: PropTypes.bool.isRequired,
  validZone: PropTypes.bool,
  isDrawing: PropTypes.bool,
  cameraResolutionHeight: PropTypes.number,
  shouldDisableEditor: PropTypes.bool,
  setChangeSetting: PropTypes.func.isRequired
}

export default RuleEditor
