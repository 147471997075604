import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .rule-editor-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 400px;
    justify-content: center;
    .rule-line {
      width: 300px;
      margin-bottom: 25px;
      align-self: flex-end;
    }
    .rule-row-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 25px;
      position: relative;
      .label-wrapper {
        font-size: 12px;
        font-weight: bold;
        margin-right: 10px;
        white-space: pre;
        span {
          font-weight: normal;
        }
      }
      .drop-down-component {
        flex: none;
        width: 190px;
      }
      .switch-wrapper {
        display: flex;
        align-items: center;
        margin-top: 27px;
        font-family: 'Prompt', sans-serif;
        .switch-label {
          font-size: 12px;
          font-weight: bold;
          margin-right: 13px;
        }
        > label {
          width: 200px;
          margin-right: 0px;
          margin-left: 0px;
        }
      }
      .slider-wrapper {
        margin-top: 27px;
      }
      .color-range-list-wrapper {
        margin-top: 24px;
        margin-bottom: 24px;
        margin-right: -10px;
        display: flex;
        flex-direction: column;
        align-items: start;
        .sub-rule-line {
          width: 198px;
          margin-bottom: 32px;
        }
        .color-range-list-label {
          font-family: 'Prompt-Medium', sans-serif;
          font-size: 12px;
          color: ${(props) => props.theme.darkBackground};
          display: flex;
          margin-bottom: 50px;
          width: 100%;
          position: relative;
          .color-range-label {
            font-family: 'Prompt-Bold', sans-serif;
          }
          .color-range-hint {
            position: absolute;
            top: 20px;
            white-space: pre;
          }
          .icon-wrapper {
            width: 16px;
            height: 16px;
            margin-left: 8px;
            cursor: pointer;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .color-range-tooltip {
            width: 250px;
            padding: 16px;
            border-radius: 5px;
            box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
            background: white;
            font-family: 'Prompt-Medium';
            font-size: 12px;
            position: absolute;
            right: -270px;
            z-index: 1;
            p {
              margin-top: 0;
            }
            div {
              display: grid;
            }
          }
        }
        .list-card-wrapper {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 200px;
          .card-wrapper {
            margin-bottom: 34px;
          }
          .range-picker-block {
            margin-bottom: 34px;
          }
        }
        #add-color-range-btn {
          font-family: 'Prompt', sans-serif;
          font-size: 12px;
          width: 200px;
          height: 40px;
          margin-top: 24px;
        }
      }
      .rule-checkbox-wrapper {
        display: flex;
        flex-direction: column;
        width: 190px;
        margin-top: 10px;
        .cb-slider-wrapper {
          .each-color-slider {
            margin-left: 30px;
            margin-top: 10px;
          }
        }
      }
    }
    .condition-row-container {
      width: 100%;
      position: relative;
      display: grid;
      grid-template-columns: 47% 53%;
      grid-template-rows: auto;
      grid-column-gap: 20px;
      .condition-label-wrapper {
        align-items: flex-end;
        .label {
          font-family: 'Prompt-Bold', sans-serif;
          font-size: 12px;
          color: ${(props) => props.theme.darkBackground};
        }
        .sub-label {
          font-family: 'Prompt', sans-serif;
          font-size: 12px;
          font-style: italic;
          color: ${(props) => props.theme.optionalText};
        }
      }
      .checkbox-wrapper {
        width: max-content;
        justify-self: right;
        position: absolute;
        left: 52%;
        top: -12px;
      }
    }
    .condition-slider-wrapper {
      width: 100%;
      align-items: flex-end;
      margin-bottom: 25px;
      position: relative;
      & > div:not(:first-child) {
        margin-top: 27px;
      }
      .truck-label {
        font-family: 'Prompt', sans-serif;
        font-weight: 600;
        position: absolute;
        left: 52%;
      }

      .MuiFormControlLabel-root {
        margin-left: -12px;
      }
    }
    .grid-color-wrapper {
      width: 400px;
      display: grid;
      grid-template-columns: 47% 53%;
      grid-template-rows: auto;
      grid-column-gap: 20px;
      .grid-label {
        font-family: 'Prompt-Bold', sans-serif;
        font-weight: bold;
        font-size: 12px;
        color: ${(props) => props.theme.darkBackground};
        justify-self: flex-end;
      }
      .color-select {
        display: grid;
        grid-template-columns: repeat(5, 30px);
        row-gap: 13px;
        .color-selector-wrapper {
          width: 19px;
          height: 19px;
          border-radius: 100%;
          background: transparent;
          display: flex;
          justify-content: center;
          align-items: center;
          .color-dot {
            width: 15px;
            height: 15px;
            border-radius: 100%;
            &:hover {
              cursor: pointer;
            }
          }
          &.active {
            border: 2px solid ${(props) => props.theme.jadeGreen};
          }
        }
      }
    }
    .apply-btn-wrapper {
      width: 100%;
      margin-top: 50px;
      display: flex;
      justify-content: flex-end;
      #apply-btn {
        width: 190px;
        font-size: 14px;
      }
    }
  }
`
