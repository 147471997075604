const MESSAGE = {
  EN: {
    event_label: 'DETECTION TYPE',
    allow_label: 'ALLOW',
    target_label: 'TARGET',
    sensitivity_label: 'Alert confidence level',
    detection_confidence_tooltip:
      'Confidence level of the system that will trigger violation alert. Higher level means the system has to be more certain that the violation actually happened before sending out any alert, implying fewer false alerts, however, increasing the possibility of missing out on the violation that the system is unsure of. ',
    min_height_tooltip:
      'Minimum height of a person that will be detected by the model. If the person is smaller than this threshold, it will not be detected.',
    min_size_tooltip:
      'Minimum size of an object that will be detected by the model. If the object is smaller than this threshold, it will not be detected.',
    min_height_label: 'Minimum height detection',
    min_size_label: 'Minimum size detection',
    color_range_title: 'Color range detection',
    color_range_hint_title: 'You can add up to 6 color ranges',
    add_range_button: 'Add color range',
    color_range_tooltip_1: `Color range detection will 
    allow customised color range 
    to be set for each farm. 
    
    Click to pick the color from palatte 
    or adjust the number from 0 - 359.`,
    color_range_tooltip_orange: 'Orange shades (15 - 34)',
    color_range_tooltip_yellow: 'Yellow shades (35 - 69)',
    color_range_tooltip_green: 'Green shades (70 - 184)',
    color_range_tooltip_blue: 'Blue shades (185 - 259)',
    color_range_tooltip_purple: 'Purple shades (260 - 299)',
    color_range_tooltip_red: 'Red shades (300 - 14)',
    all_label: 'All',
    condition_label: 'ALERT CONDITION',
    optional_label: 'optional',
    grid_color_label: 'GRID COLOR',
    apply_btn: 'Apply',
    processing_btn: 'Processing...',
    select_target_dropdown: 'Select Target',
    select_condition_dropdown: 'Select Condition',
    select_event_dropdown: 'Select Event',
    slider_low_label: 'Low',
    slider_high_label: 'High',
    slider_small_label: 'Small',
    slider_big_label: 'Big',
    slider_short_label: 'Short',
    slider_tall_label: 'Tall',
    truck: 'Truck',
    truck_condition: 'Whenever the truck is in the zone'
  },
  TH: {
    event_label: 'ประเภทการตรวจพบ',
    allow_label: 'อนุญาต',
    target_label: 'เป้าหมาย',
    sensitivity_label: 'ระดับความมั่นใจในการตรวจจับ',
    min_height_label: 'ความสูงขั้นต่ำเพื่อตรวจจับ',
    min_size_label: 'ขนาดขั้นต่ำเพื่อตรวจจับ',
    detection_confidence_tooltip:
      'ระดับความมั่นใจในการตรวจจับเพื่อแจ้งเตือน ยิ่งตั้งค่าสูงมากเท่าไร ยิ่งหมายถึงต้องการให้ตรวจจับและแจ้งเตือนก็ต่อเมื่อแน่ใจว่ามีการละเมิดกฏอย่างแท้จริงเท่านั้น ส่งผลให้เกิดการเตือนผิดพลาดลดลง แต่อาจทำให้มีบางกรณีที่ระบบไม่มั่นใจไม่เกิดการแจ้งเตือน',
    min_height_tooltip: 'ขนาดความสูงขั้นต่ำของตัวบุคคลที่ระบบจะตรวจจับ ถ้าตัวบุคคลมีขนาดเล็กกว่าเกณฑ์ที่ตั้งค่านี้จะไม่ถูกตรวจจับ',
    min_size_tooltip: 'ขนาดขั้นต่ำของวัตถุที่ระบบจะตรวจจับ ถ้าวัตถุมีขนาดเล็กกว่าเกณฑ์ที่ตั้งค่านี้จะไม่ถูกตรวจจับ',
    color_range_title: 'การตรวจจับช่วงสี',
    color_range_hint_title: 'คุณสามารถเพิ่มได้สูงสุด 6 ช่วงสี',
    add_range_button: 'เพิ่มช่วงสี',
    color_range_tooltip_1: `การตรวจจับช่วงสี จะช่วยให้
    สามารถกำหนดช่วงสีการตรวจจับ
    แต่ละฟาร์มได้แม่นยำขึ้น 
    
    คลิกเพื่อเลือกสีจากพาเลท
    หรือปรับค่าตัวเลขตั้งแต่ 0 - 359
    `,
    color_range_tooltip_orange: 'เฉดสีส้ม (15 - 34)',
    color_range_tooltip_yellow: 'เฉดสีเหลือง (35 - 69)',
    color_range_tooltip_green: 'เฉดสีเขียว (70 - 184)',
    color_range_tooltip_blue: 'เฉดสีน้ำเงิน (185 - 259)',
    color_range_tooltip_purple: 'เฉดสีม่วง (260 - 299)',
    color_range_tooltip_red: 'เฉดสีแดง (300 - 14)',
    all_label: 'ทั้งหมด',
    condition_label: 'เงื่อนไขการเตือน',
    optional_label: 'ไม่จำเป็น',
    grid_color_label: 'สีลากเส้นโซน',
    apply_btn: 'เริ่มใช้งาน',
    processing_btn: 'กำลังประมวลผล...',
    select_target_dropdown: 'เลือกเป้าหมาย',
    select_condition_dropdown: 'เลือกเงื่อนไข',
    select_event_dropdown: 'เลือกกฎ',
    slider_low_label: 'ต่ำ',
    slider_high_label: 'สูง',
    slider_small_label: 'เล็ก',
    slider_big_label: 'ใหญ่',
    slider_short_label: 'เล็ก',
    slider_tall_label: 'ใหญ่',
    truck: 'รถบรรทุก',
    truck_condition: 'เมื่อมีรถบรรทุกอยู่ในโซน '
  }
}

export default MESSAGE
